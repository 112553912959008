import React from 'react';

import AppRouter from './app/AppRouter';

function App() {

  return (
    <AppRouter/>
  );
}

export default App;
